import gql from 'graphql-tag';

export const GET_USERS = gql`
query userList {
    users(search: "deactivated = False") {
        totalCount
        edges {
            node {
                id
                firstName
                lastName
                email
                admin
                companyAdmin
                stationManager
                station {
                    id
                    officialName
                }
                company {
                    id
                    companyName
                }
            }
        }
    }
}
`;

export const GET_USER = gql`
query  {
    currentUser {
        id
        firstName
        lastName
        email
        companyAdmin
        stationManager
        admin
        station {
            id
            officialName
        }
        company {
            id
            companyName
        }
    }
}
`;
export const GET_STATIONS = gql`
query stationList {
    stations(search: "is_open = True"){
        totalCount
        edges {
            node {
                id
                stationName
                officialName
                latitude
                longtitude
                isOpen
            }
        }
    }
}
`;

export const GET_SCENARIOS = gql`
query scenarioList {
    scenarios {
        totalCount
        edges {
            node {
                id
                name
                startDate
                endDate
                station {
                    id
                }
            }
        }
    }
}
`;

export const GET_ROUTES_FORECASTS = gql`
query routesForecastList($search: String!) {
    routesForecasts(search: $search) {
        totalCount
        edges {
            node {
                id
                opsDate
                vehicleId
                departureTime
                arrivalTime
                predFlag
                emptyFlag
                forecastDate
                label {
                    id
                    label
                }
                station {
                    id
                    stationName
                    officialName
                }
            }
        }
    }
}
`;

export const GET_ROUTES_BASE = gql`
query routeList($search: String!) {
    routesBases(search: $search) {
        totalCount
        edges {
            node {
                id
                label {
                    id
                    label
                }
                opsDow
                minTruckLoad
                vehicleType
                departureDow
                departureTime
                arrivalDow
                arrivalTime
                parkedLoad
                stationId
                scenarioId
                supportOps
                updatedBy
                isVisible
            }
        }
    }
}
`;

export const GET_ROUTES = gql`
query routeList($search: String!) {
    routes(search: $search) {
        totalCount
        edges {
            node {
                id
                opsDate
                route
                driveId
                vehicleId
                employeeName
                label {
                    id
                    label
                }
                labelConfidence
                manualLabelFlag
                station {
                    id
                    stationName
                    officialName
                }
            }
        }
    }
}
`;

export const GET_LABELS = gql`
query labelList($stationId: [ID!]) {
    mostFrequentlyUsedLabels(stationId: $stationId) {
        id
        label
        memo
        labelOld
        enabled
        user {
           id
           firstName
           lastName
        }
        stationId
    }
}
`;

export const GET_TRUCKS = gql`
query truckList($search: String!) {
    trucks(search: $search) {
        totalCount
        edges {
            node {
                id
                opsDate
                vehicleId
                vehicleType
                maxTruckCapacity
                driverLicenseClass
                station {
                    id
                    stationName
                    officialName
                }
            }
        }
    }
}
`;

export const GET_TRUCK_TYPES = gql`
query truckTypes {
    truckTypes
}`;


export const GET_ROUTES_FORECAST = gql`
query routesForecast($search: String!) {
    routesForecasts(search: $search) {
        totalCount
        edges {
            node {
                id
                opsDate
                vehicleId
                vehicleType
                emptyFlag
                predFlag
                maxTruckCapacity
                driverLicenseClass
                label {
                    id
                    label
                }
                parkedLoad
                departureTime
                arrivalTime
                stationId
                vehicleStationId
                pending
            }
        }
    }
}
`;

export const GET_HEATMAP_FORECASTS = gql`
query heatmapForecasts($fromDate: String!, $toDate: String!, $stationId: ID, $stationIds: [ID!], $dateRange: [String!], $filterAvailable: Boolean) {
    heatmapForecasts(fromDate: $fromDate, toDate: $toDate, stationId: $stationId, stationIds: $stationIds, dateRange: $dateRange, filterAvailable: $filterAvailable) {
        id
        opsDate
        vehicleId
        vehicleType
        emptyFlag
        predFlag
        maxTruckCapacity
        driverLicenseClass
        label {
            id
            label
        }
        parkedLoad
        departureTime
        arrivalTime
        stationId
        vehicleStationId
        pending
        postingId
        employeeId
        employeeName
        request {
            from {
                id
            }
            truck {
                vehicleId
            }
            to {
                id
            }
            status
        }
    }
}
`;

export const GET_ARCHIVED_FORECASTS = gql`
query archivedForecasts {
    archivedForecasts {
        id
        opsDate
        vehicleId
        vehicleType
        emptyFlag
        predFlag
        maxTruckCapacity
        driverLicenseClass
        label {
            id
            label
        }
        parkedLoad
        departureTime
        arrivalTime
        stationId
        vehicleStationId
        pending
        station {
            officialName
        }
        archivedBy {
            lastName
            firstName
        }
        archivedAt
    }
}
`;


export const GET_FORECAST_AVAILABLE_TRUCKS= gql`
query forecastAvailableTrucks($fromDate: String!, $toDate: String!, $stationId: [ID!]!) {
    forecastAvailableTrucks(fromDate: $fromDate, toDate: $toDate, stationId: $stationId) {
        id
        opsDate
        vehicleId
        vehicleType
        maxTruckCapacity
        driverLicenseClass
        station {
            id
            stationName
            officialName
        }
    }
}
`;

export const GET_REQUESTS = gql`
query requestList {
    requests {
        totalCount
        edges {
            node {
                id
                status
                remarks
                opsDate
                price
                updatedAt
                createdAt
                sender {
                    id
                    firstName
                    lastName
                }
                user {
                    id
                    firstName
                    lastName
                }
                truck {
                    id
                    vehicleType
                    vehicleId
                    driverLicenseClass
                    maxTruckCapacity
                }
                from {
                    id
                    stationName
                    officialName
                }
                to {
                    id
                    stationName
                    officialName
                }
                routesForecast {
                    id
                    departureTime
                    arrivalTime
                    employeeId
                    employeeName
                    route {
                        id
                        route
                    }
                    label {
                        id
                        label
                    }
                }
            }
        }
    }
}
`;

export const GET_DASHBOARD_ROUTES_COUNT = gql`
query dashboardRoutesCount($opsDate: String!) {
    dashboardRoutesCount(opsDate: $opsDate)
}
`

export const GET_DASHBOARD_TRUCKS_COUNT = gql`
query dashboardTrucksCount($referenceMonth: String!, $threshold: Float!) {
    dashboardTrucksCount(referenceMonth: $referenceMonth, threshold: $threshold)
}
`

export const GET_TRUCK_STATS = gql`
query truckStats($referenceMonth: String!) {
    truckStats(referenceMonth: $referenceMonth)
}
`
export const GET_DAILY_EMPTY_TRUCKS = gql`
query dailyEmptyTrucks($fromDate: String!, $toDate: String!, $stationIds: [ID!]!) {
    dailyEmptyTrucks(fromDate: $fromDate, toDate: $toDate, stationIds: $stationIds)
}
`

export const AUTH_CHECK = gql`
query authCheck {
    users {
        totalCount
    }
}`;

export const GET_MAXIMUM_ROUTE_OPS_DATE = gql`
query maximumRouteOpsDate {
    maximumRouteOpsDate
}
`;

export const GET_POSTED_TRUCKS = gql`
query postedTrucks($search: String!) {
    postings(search: $search) {
        totalCount
        edges {
            node {
                id
                opsDate
                status
                startDate
                endDate
                traboxStatus
                routesForecastId
                station {
                    id
                    stationName
                    officialName
                }
                truck {
                    id
                    vehicleType
                    vehicleId
                    driverLicenseClass
                    maxTruckCapacity
                }
                user {
                    id
                    firstName
                    lastName
                    company {
                        id
                        companyName
                    }
                }
                truckPostingComments {
                    id
                    commentText
                    user {
                        id
                        firstName
                        lastName
                    }
                    createdAt
                }
                createdAt
                updatedAt
            }
        }
    }
}`;

export const ASK_QUESION = gql`
query askQuestion($messages: String!) {
    askQuestion(messages: $messages)
}`;

export const GET_FREIGHT_POSTING = gql`
query freightPosting($id: Int!) {
    freightPosting(id: $id) {
        id
        slipNumber
        stationId
        deliveryDate
        pickupDate
        maxTruckCapacity
        vehicleType
        price
        memo
        createdAt
        updatedAt
        status
        vehicleId
        traboxStatus
        deliverer {
            id
            firstName
            lastName
            company {
                contractor
            }
            station {
                id
            }
        }
        station {
            id
            stationName
            officialName
        }
        user {
            id
            firstName
            lastName
        }
        company {
            id
            companyName
        }
    }
}`;

export const GET_FREIGHT_POSTINGS = gql`
query freightPostingList {
    freightPostings {
        edges {
            node {
                id
                slipNumber
                stationId
                deliveryDate
                pickupDate
                maxTruckCapacity
                vehicleType
                price
                memo
                createdAt
                updatedAt
                status
                vehicleId
                traboxStatus
                station {
                    id
                    stationName
                    officialName
                }
                user {
                    id
                    firstName
                    lastName
                }
                company {
                    id
                    companyName
                }
            }
        }
    }
}`;

export const GET_FREIGHT_POSTING_COMMENT = gql`
query freightPosting($id: Int!) {
    freightPosting(id: $id) {
        id
        freightPostingComments {
            id
            commentText
            user {
            id
            firstName
            lastName
            }
            createdAt
        }
    }
}`;

export const GET_COMPANY = gql`
query companyList {
    companies {
        edges {
            node {
            id
            companyName
            contractor
            memo
            createdAt
            updatedAt
            }
        }
    }
}`;

export const GET_SIMULATIONS = gql`
query {
    simulations {
        edges {
            node {
                id
                s3ObjectKey
                presignedDownloadUrl
                status
                progress
                stationIds
                scenarioIds
                createdAt
            }
        }
    }
}`;