import React from 'react';
import { Drawer, Form, Select, InputNumber, Button, message } from 'antd';
import { SEND_TRABOX_FOR_FREIGHT_POSTING } from '../../mutations';
import { useMutation } from '@apollo/client';
import { departurePref, freightMode, freightType, nTrucks, highwayOptions } from './TraboxLabels';
import { isMobile } from '../../Utilities/isMobile';
const TraboxDrawer = ({
    drawerOpen,
    setDrawerOpen,
    posting,
    refetch,
    initialFreightCharge }) => {

    const [form] = Form.useForm();
    const [sendTrabox] = useMutation(SEND_TRABOX_FOR_FREIGHT_POSTING, {
        onCompleted: () => {
            refetch();
            setDrawerOpen(false);
            message.success('トラボックスに投稿しました。')
        }
    });

    const onFinish = ({orig, dest, freightMode, freightType, nTrucks, freightCharge, priceIncludeHighwayCost}) => {
        const booleanHighwayCost = priceIncludeHighwayCost === '別途支払う' ? true : false;
        
        sendTrabox({
            variables: {
                input: {
                    id: posting.id,
                    orig,
                    dest,
                    freightMode,
                    freightType,
                    nTrucks,
                    priceIncludeHighwayCost: booleanHighwayCost,
                    freightCharge
                }
            }
        }).then(() => form.resetFields())
    }

    return (
        <Drawer
            title="トラボックスに投稿する"
            placement="right"
            open={drawerOpen}
            width={isMobile() ? '100vw' : 400}
            onClose={() => setDrawerOpen(false)}
        >
            <Form
                form={form}
                name="request"
                onFinish={onFinish}
                initialValues={{ remember: true,
                                 freightCharge: initialFreightCharge // Set the initial value for freightCharge
                               }}
                colon={false}
                layout="vertical"
            >
                <Form.Item label="出発" name='orig' rules={[{ required: true }]}>
                    <Select
                        showSearch
                        placeholder="選択してください"
                        style={{ width: 300 }}
                        allowClear
                        options={departurePref.map(pref => ({ label: pref,
                             value: pref }))}
                    />
                </Form.Item>
                <Form.Item label="到着" name='dest' rules={[{ required: true }]}>
                    <Select
                        showSearch
                        placeholder="選択してください"
                        style={{ width: 300 }}
                        allowClear
                        options={departurePref.map(pref => ({ label: pref,
                             value: pref }))}
                    />
                </Form.Item>
                <Form.Item label="荷姿" name='freightMode' rules={[{ required: true }]}>
                    <Select
                        placeholder="選択してください"
                        style={{ width: 300 }}
                        allowClear
                        options={freightMode.map(weight => ({ label: weight, value: weight }))}
                    />
                </Form.Item>
                <Form.Item label="荷種類" name='freightType' rules={[{ required: true }]}>
                    <Select
                        showSearch
                        placeholder="選択してください"
                        style={{ width: 300 }}
                        allowClear
                        options={freightType.map(model => ({ label: model, value: model }))}
                    />
                </Form.Item>
                <Form.Item label="台数" name='nTrucks' rules={[{ required: true }]}>
                    <Select
                        placeholder="選択してください"
                        style={{ width: 300 }}
                        allowClear
                        options={nTrucks.map(model => ({ label: model, value: model }))}
                    />
                </Form.Item>
                <Form.Item label="高速代" name='priceIncludeHighwayCost' rules={[{ required: true }]}>
                    <Select
                        placeholder="選択してください"
                        style={{ width: 300 }}
                        allowClear
                        options={highwayOptions.map(model => ({ label: model, value: model }))}
                    />
                </Form.Item>
                <Form.Item label="１台あたりの料金" name='freightCharge' rules={[{ required: true }]}>
                    <InputNumber />
                </Form.Item>
                <Form.Item >
                    <Button type="primary" htmlType="submit" style = {{width: 300}}>トラボックスに投稿する</Button>
                </Form.Item>
            </Form>
        </Drawer>
    )
}

export default TraboxDrawer;