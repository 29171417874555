import gql from 'graphql-tag';

export const CHANGE_PASSWORD = gql`
mutation changePassword($input: changePasswordUserInput!) {
    changePasswordUser(input: $input) {
        user {
            id
            email
            password
        }
    }
}
`;

export const UPDATE_USER = gql`
mutation updateUser($input: updateUserInput!) {
    updateUser(input: $input) {
        user {
            id
            lastName
            firstName
            email
            role
            admin
            companyAdmin
            station {
                id
            }
            deactivated
            company {
                id
            }
        }
    }
}`
;


export const CREATE_USER = gql`
mutation createUser($input: createUserInput!) {
    createUser(input: $input) {
        user {
            id
            firstName
            lastName
            email
            password
        }
    }
}
`;

export const DELETE_USERS = gql`
mutation deleteUser($input: deleteUserInput!) {
    deleteUser(input: $input) {
        users {
            id
            email
        }
    }
}
`;

export const PASSWORD_RESET = gql`
mutation passwordReset($input: passwordResetUserInput!) {
    passwordResetUser(input: $input) {
        user {
            id
            email
            password
        }
    }
}`
;

export const CREATE_REQUEST = gql`
mutation createRequest($input: createRequestInput!) {
    createRequest(input: $input) {
        request {
            id,
            from {
                id
                stationName
            }
            to {
                id
                stationName
            }
            user {
                id
                email
                firstName
                lastName
            }
        }
    }
}`
;

export const CREATE_LABEL = gql`
mutation createLabel($input: createLabelInput!) {
    createLabel(input: $input) {
        label {
            id
        }
    }
}`
;

export const CREATE_ROUTE_BASE = gql`
mutation createRouteBase($input: createRouteBaseInput!) {
    createRouteBase(input: $input) {
        routesBases {
            id
        }
    }
}`
;

export const CREATE_ROUTES_FORECAST = gql`
mutation createRoutesForecast($input: createRoutesForecastInput!) {
    createRoutesForecast(input: $input) {
        routesForecast {
            id
            opsDate
            vehicleId
            departureTime
            arrivalTime
        }
    }
}`
;

export const ARCHIVE_ROUTES_FORECAST = gql`
mutation archiveRoutesForecast($input: archiveRoutesForecastInput!) {
    archiveRoutesForecast(input: $input) {
        routesForecast {
            id
        }
    }
}`

export const UPDATE_ROUTES_FORECAST = gql`
mutation updateRoutesForecast($input: updateRoutesForecastInput!) {
    updateRoutesForecast(input: $input) {
        routesForecast {
            id
            opsDate
            vehicleId
            departureTime
            arrivalTime
            stationId
            vehicleStationId
            driverLicenseClass
            emptyFlag
            vehicleType
            label {
                id
                label
            }
            maxTruckCapacity
            pending
            predFlag
        }
        overlaps
        errors
    }
}`
;

export const UPDATE_ROUTE_BASE = gql`
mutation updateRouteBase($input: updateRouteBaseInput!) {
    updateRouteBase(input: $input) {
        routesBase {
            id
        }
        overlaps {
            id
            vehicleId
            pending
            arrivalTime
            departureTime
        }
        forecast {
            id
            vehicleId
            pending
            arrivalTime
            departureTime
        }
    }
}`
;

export const UPDATE_ROUTE = gql`
mutation updateRoute($input: updateRouteInput!) {
    updateRoute(input: $input) {
        route {
            id
        }
    }
}`
;

export const UPDATE_LABEL = gql`
mutation updateLabel($input: updateLabelInput!) {
    updateLabel(input: $input) {
        label {
            id
        }
    }
}`
;

export const DELETE_ROUTES = gql`
mutation deleteRoute($input: deleteRouteInput!) {
    deleteRoute(input: $input) {
        routes {
            id
        }
    }
}
`;

export const DELETE_ROUTE_BASE  = gql`
mutation deleteRouteBase($input: deleteRouteBaseInput!) {
    deleteRouteBase(input: $input) {
        routesBase {
            id
        }
    }
}
`;

export const CREATE_SCENARIO = gql`
mutation createScenario($input: createScenarioInput!) {
    createScenario(input: $input) {
        scenario {
            id
        }
    }
}
`;

export const UPDATE_SCENARIO = gql`
mutation updateScenario($input: updateScenarioInput!) {
    updateScenario(input: $input) {
        scenario {
            id
        }
    }
}
`;

export const DELETE_SCENARIO = gql`
mutation deleteScenario($input: deleteScenarioInput!) {
    deleteScenario(input: $input) {
        scenario {
            id
        }
    }
}
`;

export const UPDATE_REQUEST = gql`
mutation updateRequest($input: updateRequestInput!) {
    updateRequest(input: $input) {
        request {
            id
            status
            overlaps {
                id
                vehicleId
                pending
                arrivalTime
                departureTime
            }
        }
        errors
    }
}`;

export const CREATE_POSTING = gql`
mutation createPosting($input: createPostingInput!) {
    createPosting(input: $input) {
        posting {
            id
        }
    }
}`;

export const UPDATE_POSTING = gql`
mutation updatePosting($input: updatePostingInput!) {
    updatePosting(input: $input) {
        posting {
            id
        }
    }
}`;

export const DELETE_POSTING = gql`
mutation deletePosting($input: deletePostingInput!) {
    deletePosting(input: $input) {
        posting {
            id
        }
    }
}`;

export const SEND_TRABOX_FOR_POSTING = gql`
mutation sendTraboxForPosting($input: sendTraboxForPostingInput!) {
    sendTraboxForPosting(input: $input) {
        posting {
            id
        }
        message
    }
}`;

export const CREATE_FREIGHT_POSTING = gql`
mutation createFreightPosting($input: createFreightPostingInput!) {
    createFreightPosting(input: $input) {
        freightPosting {
            id
        }
    }
}`;

export const UPDATE_FREIGHT_POSTING = gql`
mutation updateFreightPosting($input: updateFreightPostingInput!) {
    updateFreightPosting(input: $input) {
      freightPosting {
          id
          status
          vehicleId
        }
    }
}`;

export const DELETE_FREIGHT_POSTING = gql`
mutation deleteFreightPosting($input: deleteFreightPostingInput!) {
    deleteFreightPosting(input: $input) {
        freightPosting {
            id
        }
    }
}`;

export const SEND_TRABOX_FOR_FREIGHT_POSTING = gql`
mutation sendTraboxForFreightPosting($input: sendTraboxForFreightPostingInput!) {
    sendTraboxForFreightPosting(input: $input) {
        freightPosting {
            id
        }
        message
    }
}`;

export const CREATE_FREIGHT_POSTING_COMMENT = gql`
mutation createFreightPostingComment($input: createFreightPostingCommentInput!) {
    createFreightPostingComment(input: $input) {
        freightPostingComment {
            id
            commentText
        }
    }
}`;

export const UPDATE_FREIGHT_POSTING_COMMENT = gql`
mutation updateFreightPostingComment($input: updateFreightPostingCommentInput!) {
    updateFreightPostingComment(input: $input) {
        freightPostingComment {
            id
            commentText
        }
    }
}`;

export const DELETE_FREIGHT_POSTING_COMMENT = gql`
mutation deleteFreightPostingComment($input: deleteFreightPostingCommentInput!) {
    deleteFreightPostingComment(input: $input) {
      freightPostingComment {
          id
          commentText
       }
    }
}`;

export const CREATE_COMPANY = gql`
mutation createCompany($input: createCompanyInput!) {
    createCompany(input: $input) {
        company {
            id
            companyName
            contractor
            memo
        }
    }
}`;

export const UPDATE_COMPANY = gql`
mutation updateCompany($input: updateCompanyInput!) {
    updateCompany(input: $input) {
        company {
            id
            companyName
            contractor
            memo
        }
    }
}`;

export const DELETE_COMPANY = gql`
mutation deleteCompany($input: deleteCompanyInput!) {
    deleteCompany(input: $input) {
        companies {
            id
            companyName
            contractor
            memo
        }
    }
}`;

export const CREATE_SIMULATION = gql`
mutation createSimulation($input: createSimulationInput!) {
    createSimulation(input: $input) {
        simulation {
            id
            status
        }
    }
}`;

export const DELETE_SIMULATIONS = gql`
mutation deleteSimulation($input: deleteSimulationInput!) {
    deleteSimulation(input: $input) {
        simulation {
            id
        }
        message
    }
}`;

export const UPDATE_SIMULATIONS = gql`
mutation updateSimulation($input: updateSimulationInput!) {
    updateSimulation(input: $input) {
        simulation {
            id
            progress
        }
    }
}`;

export const CREATE_TRUCK_POSTING_COMMENT = gql`
mutation createTruckPostingComment($input: createTruckPostingCommentInput!) {
    createTruckPostingComment(input: $input) {
        truckPostingComment {
            id
            commentText 
        }
    }
}`;

export const UPDATE_TRUCK_POSTING_COMMENT = gql`
mutation updateTruckPostingComment($input: updateTruckPostingCommentInput!) {
    updateTruckPostingComment(input: $input) {
        truckPostingComment {
            id
            commentText
        }
    }
}`;

export const DELETE_TRUCK_POSTING_COMMENT = gql`
mutation deleteTruckPostingComment($input: deleteTruckPostingCommentInput!) {
    deleteTruckPostingComment(input: $input) {
        truckPostingComment {
            id
            commentText
        }
    }
}`;

export const CREATE_SEARCH_HISTORY = gql`
mutation createSearchHistory($input: createSearchHistoryInput!) {
  createSearchHistory(input: $input) {
    searchHistory {
      id
      userId
      companyIds
      stationIds
      startDate
      endDate
      searchText
    }
  }
}
`;
