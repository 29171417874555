import React, { useState, useMemo, useEffect } from 'react';
import {
    Typography,
    Table,
    Row,
    Col,
    Form,
    Select,
    DatePicker,
    Card,
    Button,
    Input,
    Spin,
    Space,
    Tag,
    Tabs,
    Divider,
} from 'antd';
import { PlusOutlined, SearchOutlined, MessageOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { GET_STATIONS, GET_FREIGHT_POSTINGS } from '../../queries';
import PostingDrawer from './PostingDrawer';
import moment from 'moment';
import { filterRequestsByTabAndSearchText } from '../../Utilities/searchUtils';
import DetailDrawer from './DetailDrawer';
import { useStickyState } from '../../Utilities/useStickyState';
import { useParams } from 'react-router-dom';
import TraboxDrawer from './TraboxDrawer';
import { localeInDatepicker } from '../../Utilities/datepickerWithTimeInForm'
import { taggify } from '../../Utilities/taggify';
import { isMobile } from '../../Utilities/isMobile';

const FreightPosting = ({ history }) => {
    const { postingId } = useParams();
    const { data: stationsData } = useQuery(GET_STATIONS);
    const [tabFilter, setTabFilter] = useStickyState('sent', 'freightPostingTabFilter', 'active');
    const [station, setStation] = useState([]);
    const [dateRange, setDateRange] = useState([]);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [originalFreightPostings, setOriginalFreightPostings] = useState([]);
    const [detailDrawerOpen, setDetailDrawerOpen] = useState(false);
    const [detail, setDetail] = useState({});
    const [showTraboxDrawer, setShowTraboxDrawer] = useState(false);
    const [newPostingButtonLoading, setNewPostingButtonLoading] = useState(false);
    const [form] = Form.useForm();
    const { data, refetch, startPolling } = useQuery(GET_FREIGHT_POSTINGS, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        onCompleted: (data) => {
            const freightPostings = data.freightPostings.edges.map(posting => ({
                key: posting.node.id,
                id: posting.node.id,
                slipNumber: posting.node.slipNumber,
                deliveryDate: moment(posting.node.deliveryDate).locale('ja').format('YYYY-MM-DD HH:mm'),
                pickupDate: moment(posting.node.pickupDate).locale('ja').format('YYYY-MM-DD HH:mm'),
                stationName: posting.node.station?.officialName,
                stationId: String(posting.node.stationId),
                maxTruckCapacity: posting.node.maxTruckCapacity,
                vehicleType: posting.node.vehicleType,
                price: posting.node.price,
                postedBy: {
                    id: posting.node.user.id,
                    name: `${posting.node.user.lastName} ${posting.node.user.firstName}`,
                },
                companyName: posting.node.company?.companyName,
                stationOrCompany: posting.node.station?.officialName || posting.node.company?.companyName,
                postedDate: posting.node.createdAt,
                remarks: posting.node.memo,
                comments: posting.node.freightPostingComments,
                status: posting.node.status,
                traboxStatus: posting.node.traboxStatus,
            }));
            setOriginalFreightPostings(freightPostings);
            startPolling(10000);
        }
    });

    const columns = [
        {
            render: (text, record) =>
                <div style={{ width: '100%', textAlign: 'center'}}>
                    <MessageOutlined
                        style={{ fontSize: '24px' }}
                        onClick={() => { setDetailDrawerOpen(true); setDetail(record); }}
                    />
                </div>,
            width: 20
        },
        {
            title: <Typography.Text strong>ID</Typography.Text>,
            dataIndex: 'id',
            width: 25
        },
        {
            title: <Typography.Text strong>営業所</Typography.Text>,
            dataIndex: 'stationName',
            width: 45
        },
        {
          title: <Typography.Text strong>会社名</Typography.Text>, 
            dataIndex: 'companyName',
            width: 45
        },
        {
            title: <Typography.Text strong>荷物の詳細</Typography.Text>,
            dataIndex: 'remarks',
            width: 200
        },
        {
            title: <Typography.Text strong>集荷日時</Typography.Text>,
            dataIndex: 'pickupDate',
            sorter: (a, b) => new Date(a.pickupDate) - new Date(b.pickupDate),
            width: 60
        },
        {
            title: <Typography.Text strong>納品日時</Typography.Text>,
            dataIndex: 'deliveryDate',
            sorter: (a, b) => new Date(a.deliveryDate) - new Date(b.deliveryDate),
            width: 60
        },
        {
            title: <Typography.Text strong>車種</Typography.Text>,
            dataIndex: 'vehicleType',
            width: 35
        },
        {
            title: <Typography.Text strong>積載重量</Typography.Text>,
            dataIndex: 'maxTruckCapacity',
            width: 30
        },
        {
            title: <Typography.Text strong>料金</Typography.Text>,
            dataIndex: 'price',
            width: 40
        },
        {
            title: <Typography.Text strong>投稿者</Typography.Text>,
            dataIndex: 'postedBy',
            width: 40,
            render: rowContent => <div>{rowContent.name}</div>
        },
        {
            title: <Typography.Text strong>ステータス</Typography.Text>,
            dataIndex: 'status',
            width: 40,
            render: taggify,
        },
        {
            title: <Typography.Text strong>トラボックス進捗</Typography.Text>,
            render: (rowContent, record) => {
                if (!rowContent) {
                    return <Button type='link' onClick={()=> {setShowTraboxDrawer(true); setDetail(record)}}>トラボックスに送信する</Button>
                } else if (rowContent === 'sent') {
                    return <Tag color='blue'>処理中</Tag>
                } else if (rowContent === 'success') {
                    return <Tag color='green'>投稿完了</Tag>
                } else if (rowContent === 'failed') {
                    return <Tag color='red'>投稿失敗</Tag>
                }
            },
            dataIndex: 'traboxStatus',
            width: 80,
            fixed: 'right'
        },
        {
            title: <div></div>,
            dataIndex: 'acceptButton',
            width: 10,
            fixed: 'right'
        },
    ]

    useEffect(() => {
        if (postingId && originalFreightPostings.length) {
            setDetailDrawerOpen(true);
            setDetail(originalFreightPostings.find(posting => posting.id === postingId));
        }
    }, [postingId, originalFreightPostings]);

    const filteredFreightPostings = useMemo(() => {
        let filterdFlightPosting = [...originalFreightPostings];

        if (station.length) {
            filterdFlightPosting = filterdFlightPosting.filter(posting => station.includes(posting.stationId));
        }
        if (dateRange.length) {
            filterdFlightPosting = filterdFlightPosting.filter(posting => {
                const deliveryDate = moment(posting.deliveryDate)
                const pickupDate = moment(posting.pickupDate)
                return pickupDate.isSameOrAfter(dateRange[0].startOf('day')) && deliveryDate.isSameOrBefore(dateRange[1].endOf('day'));
            });
        }
        if (searchText.length) {
            filterdFlightPosting = filterRequestsByTabAndSearchText(filterdFlightPosting, null, searchText);
        }

        if (tabFilter === 'active') {
            filterdFlightPosting = filterdFlightPosting.filter(posting => ['active', 'draft', 'posted', 'requested', 'delivered'].includes(posting.status));
        } else if (tabFilter === 'archived') {
            filterdFlightPosting = filterdFlightPosting.filter(posting => posting.status === 'archived');
        }

        filterdFlightPosting = filterdFlightPosting.sort((a, b) => {
            const aPickupDate = moment(a.pickupDate);
            const bPickupDate = moment(b.pickupDate);
            return aPickupDate.isBefore(bPickupDate) ? 1 : -1;
        });

        return filterdFlightPosting;

    }, [originalFreightPostings, station, dateRange, searchText, tabFilter]);
    
    const items = [
        {
            key: 'active',
            label: '進行中',
        },
        {
            key: 'archived',
            label: '過去の投稿',
        }
    ]
    
    const FreightPostingListForMobile = () => {
        return (
            <>
                {filteredFreightPostings.map(posting => (
                    <Card key={posting.id} style={{ margin: '.5rem', padding: '1rem', borderBottom: '1px solid #f0f0f0', borderRadius: '5px' }}>
                        <Button icon={<MessageOutlined />} onClick={() => { setDetailDrawerOpen(true); setDetail(posting); }}/><br/>
                        <Typography.Text strong>ID: </Typography.Text><Typography.Text>{posting.id}</Typography.Text><br/>
                        <Typography.Text strong>営業所: </Typography.Text><Typography.Text>{posting.stationName}</Typography.Text><br/>
                        <Typography.Text strong>会社名: </Typography.Text><Typography.Text>{posting.companyName}</Typography.Text><br/>
                        <Typography.Text strong>荷物の詳細: </Typography.Text><Typography.Text>{posting.remarks}</Typography.Text><br/>
                        <Typography.Text strong>集荷日時: </Typography.Text><Typography.Text>{posting.pickupDate}</Typography.Text><br/>
                        <Typography.Text strong>納品日時: </Typography.Text><Typography.Text>{posting.deliveryDate}</Typography.Text><br/>
                        <Typography.Text strong>車種: </Typography.Text><Typography.Text>{posting.vehicleType}</Typography.Text><br/>
                        <Typography.Text strong>積載重量: </Typography.Text><Typography.Text>{posting.maxTruckCapacity}</Typography.Text><br/>
                        <Typography.Text strong>料金: </Typography.Text><Typography.Text>{posting.price}</Typography.Text><br/>
                        <Typography.Text strong>投稿者: </Typography.Text><Typography.Text>{posting.postedBy.name}</Typography.Text><br/>
                        <Typography.Text strong>ステータス: </Typography.Text>{taggify(posting.status)}<br/>
                        <Typography.Text strong>トラボックス進捗: </Typography.Text>{posting.traboxStatus ? <Tag color='blue'>処理中</Tag> : <Button type='link' onClick={()=> {setShowTraboxDrawer(true); setDetail(posting)}}>トラボックスに送信する</Button>}
                    </Card>
                ))}
            </>
        )
    }

    if (!data) {
        return <Spin/>;
    }
    return <Space direction="vertical" style={{ width: "100%" }}>
        <Card bordered={false}>
            <Form form={form} layout='inline'
                        size='large'
                        onFinish={
                            ({ date, stations, text }) => {
                                setStation(stations || []);
                                setDateRange(date || []);
                                setSearchText(text || '');
                            }
                        }
                    >
                <Row gutter={[16,8]}>
                    <Col>
                        <Form.Item name='stations' label={isMobile() ? "" : "営業所"}>
                            <Select
                                showSearch
                                mode="multiple"
                                placeholder="営業所を選択してください"
                                style={{ width: 250 }}
                                allowClear
                                >
                                {
                                    stationsData?.stations.edges.map(
                                        station => (
                                            <Select.Option key={station.node.id} value={station.node.id}>
                                                {station.node.officialName}
                                            </Select.Option>
                                        )
                                    )
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            name='date'
                            label={isMobile() ? "" : "表示期間"}
                        >
                            <DatePicker.RangePicker
                                locale={localeInDatepicker}
                                style={{ marginRight: '.5rem' }}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item name='text'>
                            <Input
                                allowClear
                                style={{ width: 200 }}
                                value={searchText}
                                placeholder="例：冷凍車、バン"
                                prefix={<SearchOutlined />}
                            />
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item shouldUpdate>
                            {
                            () => <Button htmlType="submit" type="primary">検索</Button>
                            }
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
        <Divider style={{ margin: 0, padding: 0 }}/>
        <Card bordered={false} style={{padding: '0px 0px 0px 0px'  }}>
            <Tabs defaultActiveKey={tabFilter} onChange={ activeKey => setTabFilter(activeKey) } items={items} />
            <div style={{display: 'flex', justifyContent: 'flex-end'}}>
                <Button
                    type="primary"
                    icon={<PlusOutlined />}
                    onClick={() => setDrawerOpen(true)}
                    loading={newPostingButtonLoading}
                    style={{ positon: 'absolute', top: '-70px'}}
                >
                    投稿する
                </Button>
            </div>
            {isMobile() ? <FreightPostingListForMobile />
                :<Table
                    columns={columns}
                    style={{ paddingTop: '0px', background: '#fff' }}
                    dataSource={filteredFreightPostings}
                    scroll={{ x: 1300 }}
                />
            }   
        </Card>
        <PostingDrawer
            setNewPostingButtonLoading={setNewPostingButtonLoading}
            drawerOpen={drawerOpen}
            setDrawerOpen={setDrawerOpen}
            stationsData={stationsData}
            setStation={setStation}
            refetch={refetch}
        />
        <DetailDrawer
            detailDrawerOpen={detailDrawerOpen}
            setDetailDrawerOpen={setDetailDrawerOpen}
            postingId={detail.id}
            refetchList={refetch}
            history={history}
        />
        <TraboxDrawer
            drawerOpen={showTraboxDrawer}
            setDrawerOpen={setShowTraboxDrawer}
            posting={detail}
            refetch={refetch}
            initialFreightCharge={detail.price}
            history={history}
        />
    </Space>
}

export default FreightPosting;