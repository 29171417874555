import React, { useState }from 'react';
import {
    Drawer,
    Form,
    Typography,
    List,
    Comment,
    Input,
    Button,
    Avatar,
    Popconfirm,
    message,
    Select,
    Divider
} from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { taggify } from '../../Utilities/taggify';
import {
    CREATE_FREIGHT_POSTING_COMMENT,
    UPDATE_FREIGHT_POSTING_COMMENT,
    DELETE_FREIGHT_POSTING_COMMENT,
    UPDATE_FREIGHT_POSTING
} from '../../mutations';
import { GET_FREIGHT_POSTING_COMMENT, GET_FREIGHT_POSTING, GET_USERS } from '../../queries';
import { useMutation, useQuery } from '@apollo/client';
import moment from 'moment';
import { authenticationService } from '../../Utilities/authenticationService'
import { isMobile } from '../../Utilities/isMobile';

const DetailDrawer = ({
    setDetailDrawerOpen,
    detailDrawerOpen,
    postingId,
    refetchList,
    history
}) => {
    const currentUser = authenticationService.currentUserValue;
    const [input, setInput] = useState('');
    const [form] = Form.useForm();
    const [comments, setComments] = useState([]);
    const [memo, setMemo] = useState('');
    const [deliverer, setDeliverer] = useState(null);
    const [posting, setPosting] = useState({});
    const { loading: userListLoading, error: userListError, data: userListData } = useQuery(GET_USERS, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true
    });
    const { refetch } = useQuery(GET_FREIGHT_POSTING, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: { id: parseInt(postingId) },
        onCompleted: (data) => {
            if (data.freightPosting) {
                setPosting(
                    {
                        key: data.freightPosting.id,
                        id: data.freightPosting.id,
                        slipNumber: data.freightPosting.slipNumber,
                        deliveryDate: moment(data.freightPosting.deliveryDate).locale('ja').format('YYYY-MM-DD HH:mm'),
                        pickupDate: moment(data.freightPosting.pickupDate).locale('ja').format('YYYY-MM-DD HH:mm'),
                        stationName: data.freightPosting.station?.officialName,
                        stationId: String(data.freightPosting.stationId),
                        maxTruckCapacity: data.freightPosting.maxTruckCapacity,
                        vehicleType: data.freightPosting.vehicleType,
                        price: data.freightPosting.price,
                        postedBy: {
                            id: data.freightPosting.user?.id,
                            name: `${data.freightPosting.user?.lastName} ${data.freightPosting.user?.firstName}`,
                        },
                        companyName: data.freightPosting.company?.companyName,
                        postedDate: data.freightPosting.createdAt,
                        remarks: data.freightPosting.memo,
                        comments: data.freightPosting.freightPostingComments,
                        status: data.freightPosting.status,
                        traboxStatus: data.freightPosting.traboxStatus,
                        deliverer: data.freightPosting.deliverer,
                    }
                )
                setMemo(data.freightPosting.memo);
                setDeliverer(data.freightPosting.deliverer?.id || null);
            }
        }
    });

    const { refetch: refetchComment } = useQuery(GET_FREIGHT_POSTING_COMMENT, {
        fetchPolicy: 'no-cache',
        notifyOnNetworkStatusChange: true,
        variables: { id: parseInt(postingId) },
        onCompleted: (data) => {
            const commentArr = data.freightPosting.freightPostingComments.map(comment => ({
                id: comment.id,
                userId: comment.user.id,
                author: <Typography.Text strong>{`${comment.user.lastName} ${comment.user.firstName}`}</Typography.Text>,
                avatar: <Avatar>{comment.user.lastName[0]}</Avatar>,
                content: <p key={comment.id}>{comment.commentText}</p>,
                datetime: moment(comment.createdAt).locale('ja').format('YYYY年M月D日 H:mm'),
            }));
            setComments(commentArr);
        }
    });
    const [createFreightPostingComment] = useMutation(CREATE_FREIGHT_POSTING_COMMENT, {
        onCompleted: () => {
            refetch();
            refetchComment();
            setInput('');
        }
    });
    const [updateFreightPostingComment] = useMutation(UPDATE_FREIGHT_POSTING_COMMENT, {
        onCompleted: () => {
            refetchComment();
        }
    });
    const [deleteFreightPostingComment] = useMutation(DELETE_FREIGHT_POSTING_COMMENT, {
        onCompleted: () => {
            refetchComment();
        }
    });
    const [archiveFreightPosting] = useMutation(UPDATE_FREIGHT_POSTING, {
        onCompleted: () => {
            refetch();
            refetchList();
            setDetailDrawerOpen(false);
            message.success('リクエストを削除しました。')
        }
    });
    const [updateFreightPosting] = useMutation(UPDATE_FREIGHT_POSTING, {
        onCompleted: () => {
            refetch();
            refetchList();
            message.success('リクエストを更新しました。')
        }
    })

    const handlePostingNav = () => {
        const location = {
            pathname: '/truckSchedule',
            posting_id: posting.id,
            station_id: posting.deliverer?.station?.id,
            pickup_date: posting.pickupDate,
            delivery_date: posting.deliveryDate,
        }
        history.push(location)
    }

    const onSubmit = (values) => {
        createFreightPostingComment ({
            variables: {
                input: {
                    freightPostingId: posting.id,
                    commentText: input
                }
            }
        })
    }
    return (
        <Drawer
            placement="right"
            open={detailDrawerOpen}
            width={isMobile() ? '100vw' : 500}
            onClose={() => setDetailDrawerOpen(false)}
            title={
                parseInt(currentUser.id) ===
                    parseInt(posting.deliverer?.id) &&
                        !posting.deliverer?.company?.contractor && (
                    <Button onClick={handlePostingNav} type="primary">
                        運行スケジュールに追加
                    </Button>
                )
            }
            extra={
                <>
                    {taggify(posting.status)}
                    {!currentUser.contractor &&
                        (posting.status !== "archived" ? (
                            <Popconfirm
                                title="リクエストを削除しますか？"
                                okText="削除"
                                cancelText="キャンセル"
                                placement='bottomLeft'
                                onConfirm={() => {
                                    archiveFreightPosting({
                                        variables: {
                                            input: {
                                                id: posting.id,
                                                status: "archived",
                                            },
                                        },
                                    });
                                }}
                            >
                                <DeleteOutlined
                                    style={{
                                        marginRight: 5,
                                        fontSize: 16,
                                        zIndex: 1,
                                    }}
                                />
                            </Popconfirm>
                        ) : (
                            <Popconfirm
                                title="リクエストを復元しますか？"
                                okText="復元"
                                cancelText="キャンセル"
                                placement='bottomLeft'
                                onConfirm={() => {
                                    updateFreightPosting({
                                        variables: {
                                            input: {
                                                id: posting.id,
                                                status: "posted",
                                            },
                                        },
                                    });
                                }}
                            >
                                <EditOutlined
                                    style={{
                                        marginRight: 5,
                                        fontSize: 16,
                                        zIndex: 1,
                                    }}
                                />
                            </Popconfirm>
                        ))}
                </>
            }
        >
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                    height: "100%",
                    width: "100%",
                }}
            >
                <div style={{width: "100%"}}>
                    <Typography style={{marginBottom: '5px'}}>ID：{posting.id}</Typography>
                    <Typography style={{marginBottom: '5px'}}>営業所：{posting.stationName}</Typography>
                    <Typography style={{marginBottom: '5px'}}>伝票番号：{posting.slipNumber}</Typography>
                    <Typography style={{marginBottom: '5px'}}>
                        納品日時：
                        {moment(posting.pickupDate)
                            .locale("ja")
                            .format("YYYY年M月D日 H:mm")}
                    </Typography>
                    <Typography style={{marginBottom: '5px'}}>
                        集荷日時：
                        {moment(posting.deliveryDate)
                            .locale("ja")
                            .format("YYYY年M月D日 H:mm")}
                    </Typography>
                    <Typography style={{marginBottom: '5px'}}>
                        最大積載量キロ：{posting.maxTruckCapacity}
                    </Typography>
                    <Typography style={{marginBottom: '5px'}}>車種：{posting.vehicleType}</Typography>
                    <Typography style={{marginBottom: '5px'}}>運賃：{posting.price}</Typography>
                    {
                        // If the posting is a draft and the user is the poster, show the memo input field.
                        posting.status === "draft" ? (
                            parseInt(posting.postedBy?.id) ===
                                parseInt(currentUser.id) && (
                                <>
                                    <Typography style={{marginBottom: '5px'}}>備考：</Typography>
                                    <Input.TextArea
                                        placeholder="備考を入力してください。"
                                        minRows = {10}
                                        style = {{height: 180}}
                                        value={memo}
                                        disabled={posting.status !== "draft"}
                                        onChange={(e) => {
                                            console.log(e.target.value);
                                            setMemo(e.target.value);
                                        }}
                                    />
                                </>
                            )
                        ) : (
                            // if its not a draft, show the memo.
                            <Typography style={{marginBottom: '5px'}} >備考：{posting.remarks}</Typography>
                        )
                    }
                    <Typography style={{marginBottom: '5px'}} >投稿者：{posting.postedBy?.name}</Typography>
                    {posting.deliverer && (
                        <Typography style={{marginBottom: '5px'}}>配送者：{`${posting.deliverer?.lastName} ${posting.deliverer?.firstName}`}</Typography>
                    )}
                    {posting.status === "draft" && (
                        <Button
                            type="primary"
                            onClick={() => {
                                updateFreightPosting({
                                    variables: {
                                        input: {
                                            id: posting.id,
                                            status: "posted",
                                            memo: memo,
                                        },
                                    },
                                });
                            }}
                        >
                            投稿する
                        </Button>
                    )}
                    {posting.status === "posted" &&
                        // If the user is the poster, show the deliverer selection field
                        parseInt(posting.postedBy?.id) ===
                            parseInt(currentUser.id) && (
                            <>
                                <Select
                                    showSearch
                                    placeholder="配送者を入力してください。"
                                    optionFilterProp="label"
                                    onChange={(e) => {
                                        setDeliverer(parseInt(e));
                                    }}
                                >
                                    {!userListLoading && !userListError &&
                                        userListData?.users?.edges?.map((user) => (
                                            <Select.Option
                                                key={user.node.id}
                                                value={user.node.id}
                                                label={`${user.node.lastName} ${user.node.firstName}`}
                                            >
                                                {`${user.node.lastName} ${user.node.firstName}`}
                                            </Select.Option>
                                        ))
                                    }
                                </Select>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        updateFreightPosting({
                                            variables: {
                                                input: {
                                                    id: posting.id,
                                                    status: "requested",
                                                    delivererId: deliverer,
                                                },
                                            },
                                        });
                                    }}
                                >
                                    配送者決定
                                </Button>
                            </>
                        )}
                    {posting.status === "requested" && (currentUser.id === parseInt(deliverer) || currentUser.id === parseInt(posting.postedBy.id)) &&(
                        <Button
                            type="primary"
                            onClick={() => {
                                updateFreightPosting({
                                    variables: {
                                        input: {
                                            id: posting.id,
                                            status: "delivered",
                                        },
                                    },
                                });
                            }}
                        >
                            配達完了
                        </Button>
                    )}
                <Divider />
                </div>
                <div style={{ width: "100%" }}>
                    {comments?.length ? (
                        <CommentList posting={posting} comments={comments} currentUserId={currentUser.id} deleteComment={deleteFreightPostingComment} updateComment={updateFreightPostingComment}/>
                    ) : (
                        <Typography>コメントがありません。</Typography>
                    )}

                    <Form form={form} onFinish={onSubmit}>
                        <Comment
                            avatar={<Avatar>{currentUser.last_name[0]}</Avatar>}
                            content={
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Input.TextArea
                                        placeholder="コメントを入力してください。"
                                        autoSize={{ minRows: 5, maxRows: 20 }}
                                        value={input}
                                        onChange={(e) =>
                                            setInput(e.target.value)
                                        }
                                        disabled={posting.status === "archived"}
                                    />
                                    <Button
                                        style={{ height: "auto" }}
                                        disabled={
                                            posting.status === "archived" ||
                                            input.length < 1
                                        }
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        送信
                                    </Button>
                                </div>
                            }
                        />
                    </Form>
                </div>
            </div>
        </Drawer>
    );
}

export const CommentList = ({ posting, currentUserId, comments, deleteComment, updateComment }) => {
    const [editComment, setEditComment] = useState(false);
    const [editCommentId, setEditCommentId] = useState(null);
    const [editInput, setEditInput] = useState('');
    
    return <List
        dataSource={comments}
        itemLayout="horizontal"
        style={{ maxHeight: 500, overflow: 'auto' }}
        renderItem={props =>
            <>
                { props.userId === String(currentUserId) &&
                    <div style={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        margin: '15px 10px -32px',
                    }}>
                        {posting.status !== 'archived' &&
                        <>
                            <EditOutlined
                                key="edit"
                                onClick={() => {
                                    setEditComment(true);
                                    setEditCommentId(props.id);
                                    setEditInput(props.content.props.children);
                                }}
                                style={{ marginRight: 10, fontSize: 16, zIndex: 1}}
                                />
                            <Popconfirm
                                title="コメントを削除しますか？"
                                okText="削除"
                                cancelText="キャンセル"
                                onConfirm={() => {
                                    deleteComment({
                                        variables: {
                                            input: {
                                                id: props.id
                                            }
                                        }
                                    })
                                }}
                                >
                                <DeleteOutlined style={{ marginRight: 5, fontSize: 16, zIndex: 1}}/>
                            </Popconfirm>
                        </>}
                    </div>
                }
                <Comment
                    {...props}
                    content={
                        editComment && props.id === editCommentId ?
                        <Form onFinish={
                            ({ editComment }) => {
                                if (editComment && editComment !== editInput) {
                                    updateComment({
                                        variables: {
                                            input: {
                                                id: editCommentId,
                                                commentText: editComment
                                            }
                                        }
                                    })
                                }
                                setEditComment(false);
                                }
                            }
                            style={{ width: '100%'}}
                        >
                            <Form.Item name='editComment'>
                                <div style={{ width: '100%' }}>
                                    <Input.TextArea
                                        autoSize={{ minRows: 2, maxRows: 6 }}
                                        defaultValue={editInput}
                                    />
                                </div>
                            </Form.Item>
                            <Form.Item>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%'}}>
                                    <Button htmlType="button" onClick={() => setEditComment(false)} style={{ marginRight: '1rem'}}>
                                        キャンセル
                                    </Button>
                                    <Button htmlType="submit" type="primary">
                                        保存
                                    </Button>
                                </div>
                            </Form.Item>
                        </Form>
                        :
                        props.content
                    }
                    />
                </>
            }
    />
}

export default DetailDrawer;