import React, { useState } from "react";
import { Space, Card, Tabs, Divider, Form, Row, Col, Select, DatePicker, Button, Spin, Input } from "antd";
import PostingTable from "./PostingTable/PostingTable";
import { useQuery, useMutation } from "@apollo/client";
import { GET_STATIONS, GET_COMPANY } from "../../queries";
import { CREATE_SEARCH_HISTORY } from "../../mutations"; // Ensure you have the mutation imported
import moment from "moment";
import { useParams } from 'react-router-dom';
import { localeInDatepicker } from "../../Utilities/datepickerWithTimeInForm";
import { isMobile } from "../../Utilities/isMobile";
import { SearchOutlined } from "@ant-design/icons";

const TruckPosting = () => {
    const { fromDate, toDate, station: paramsStation, company: paramsCompany } = useParams();
    const { data: stationsData, loading: loadingStations } = useQuery(GET_STATIONS);
    const { data: companiesData, loading: loadingCompanies } = useQuery(GET_COMPANY);
    const [createSearchHistory] = useMutation(CREATE_SEARCH_HISTORY); // Initialize the mutation
    const [stations, setStations] = useState(paramsStation ? [paramsStation] : []);
    const [companies, setCompanies] = useState(paramsCompany ? [paramsCompany] : []);
    const [dateRange, setDateRange] = useState((fromDate && toDate && [moment(fromDate), moment(toDate)]) || []);
    const [searchText, setSearchText] = useState('');   
    const [refresh, setRefresh] = useState(false);
    const [form] = Form.useForm();
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    const handleSearch = async ({ dateRange, station, company, searchText }) => {
        // Check if at least one of the inputs (dateRange, station, or searchText) is filled
        if ((!station || station.length === 0) && (!dateRange || dateRange.length === 0) && !searchText && !company) {
            console.warn('No search inputs provided. Skipping search history creation.');
            return;
        }

        // Set the form data to state
        setStations(station.find(e => e === 'all') ? stationsData.stations.edges.map(e => e.node.id) : station);
        setCompanies(company.find(e => e === 'all') ? companiesData.companies.edges.map(e => e.node.id) : company);
        setDateRange(dateRange);
        setSearchText(searchText);

        // Prepare date variables safely
        const startDate = dateRange && dateRange[0] ? dateRange[0].toISOString() : null;
        const endDate = dateRange && dateRange[1] ? dateRange[1].toISOString() : null;
        // Convert station and company lists from strings to integers
        const parsedStationIds = station.map((id) => parseInt(id, 10)); // Convert each ID to an integer
        const parsedCompanyIds = company.map((id) => parseInt(id, 10)); // Convert each ID to an integer

        // Call the mutation to create search history
        try {
            await createSearchHistory({
                variables: {
                    input: {
                        userId: currentUser.id, // Replace with actual user ID
                        companyIds: parsedCompanyIds, // Replace with actual company ID if available
                        stationIds: parsedStationIds, // Assuming the first station is used, adjust as necessary
                        startDate: startDate,
                        endDate: endDate,
                        searchText: searchText || "",
                    },
                },
            });
            console.log('Search history created successfully');
        } catch (error) {
            console.error('Error creating search history:', error);
        }
    };

    const SearchErea = () => {
        return <Space size={24} direction="vertical" style={{ width: "100%" }}>
            <Card bordered={false}>
                <Form
                    form={form}
                    layout='inline'
                    size='large'
                    onFinish={handleSearch}
                >
                    <Row gutter={[16, 8]}>
                        <Col>
                            <Form.Item name='company' label="会社名" initialValue={stations || []}>
                                <Select
                                    showSearch
                                    initialValue={companies || []}
                                    mode="multiple"
                                    placeholder="選択してください"
                                    style={{ width: 200 }}
                                >
                                    {
                                        companiesData?.companies?.edges.map(company => (
                                            <Select.Option key={company.node.id} value={company.node.id}>
                                                {company.node.companyName}
                                            </Select.Option>
                                        )).concat([<Select.Option key='all' value='all'>全ての会社名</Select.Option>])
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name='station' label={isMobile() ? "" : "営業所"} initialValue={stations || []}>
                                <Select
                                    showSearch
                                    initialValue={stations || []}
                                    mode="multiple"
                                    placeholder="営業所を選択してください"
                                    style={{ width: 250 }}
                                >
                                    {
                                        stationsData?.stations?.edges.map(station => (
                                            <Select.Option key={station.node.id} value={station.node.id}>
                                                {station.node.officialName}
                                            </Select.Option>
                                        )).concat([<Select.Option key='all' value='all'>全ての営業所</Select.Option>])
                                    }
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name='dateRange' label={isMobile() ? "" : "表示期間"} initialValue={dateRange || []}>
                                <DatePicker.RangePicker
                                    locale={localeInDatepicker}
                                    initialValue={dateRange || []}
                                    showTime={{defaultValue: [moment('00:00:00', 'HH:mm:ss'), moment('00:00:00', 'HH:mm:ss')]}}
                                    format="YYYY-MM-DD HH:mm:ss"
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item name="searchText" initialValue={searchText}>
                                <Input
                                    allowClear
                                    style={{ width: 200 }}
                                    value={searchText}
                                    placeholder="例：冷凍車、バン"
                                    prefix={<SearchOutlined />}
                                />
                            </Form.Item>
                        </Col>
                        <Col>
                            <Form.Item shouldUpdate>
                                {
                                    () => (
                                        <>
                                            <Button
                                                disabled={
                                                    !form.getFieldValue('station') ||
                                                    !form.getFieldValue('dateRange')
                                                }
                                                htmlType="submit" type='primary'>
                                                検索
                                            </Button>
                                        </>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </Card>
        </Space>
    }
    
    const items = [
        {
            key: 'postingTable',
            label: '進行中',
            children: (
                <PostingTable
                    dateRange={dateRange}
                    station={stations}
                    companies={companies}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    searchText={searchText}
                />
            )
        },
        {
            key: 'previous', // todo: filter by postings that have expired
            label: '過去の投稿',
            children: (
                <PostingTable
                    dateRange={dateRange}
                    station={stations}
                    companies={companies}
                    refresh={refresh}
                    setRefresh={setRefresh}
                    searchText={searchText}
                    onlyExpired={true}
                />
            )
        },
    ];

    if (loadingStations) {
        return <Spin/>;
    }

    if (loadingCompanies) {
        return <Spin/>;
    }

    return <>
        <SearchErea />
        <Divider style={{ margin: 0, padding: 0 }}/>
        <Card bordered={false} style={{ marginTop: '1.5rem' }}>
            <Tabs items={items} tabBarStyle={{ marginBottom: 'auto', borderRadius: '5px 5px 0 0'}} type="card" defaultActiveKey='truckSearch' />
        </Card>
    </>;
    
}
export default TruckPosting;
