import React, { useState, useEffect, useMemo } from 'react';
import { useMutation, useQuery, useLazyQuery } from '@apollo/client';
import { CREATE_REQUEST, CREATE_ROUTES_FORECAST, UPDATE_REQUEST, CREATE_TRUCK_POSTING_COMMENT, UPDATE_TRUCK_POSTING_COMMENT, DELETE_TRUCK_POSTING_COMMENT } from '../../../mutations';
import { GET_LABELS, GET_USERS, GET_ROUTES_FORECASTS } from '../../../queries';
import { authenticationService } from '../../../Utilities/authenticationService';
import { DatePickerWithTimeInForm } from '../../../Utilities/datepickerWithTimeInForm';
import {
    message,
    Select,
    Form,
    Input,
    Button,
    Drawer,
    InputNumber,
    Typography,
    Comment,
    Avatar,
    Table,
    Divider,
    Popconfirm
} from 'antd';
import { CloseOutlined, MessageOutlined, DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import { isMobile } from '../../../Utilities/isMobile';

import { CommentList } from '../../FreightPosting/DetailDrawer';

const TruckSearchRequestDrawer = ({ visible=false, setVisible, stationsData, handleDeletePosting, truck, dateRange, posting = {}, paramsVehicleId, refetchPostings, disableToId=false, buttonMode, traboxButton }) => {
    const currentUser = authenticationService.currentUserValue

    const searchedStartTime = useMemo(() => moment(dateRange[0]), [dateRange])
    const searchedEndTime = useMemo(() => moment(dateRange[1]), [dateRange])
    const [stationId, setStationId] = useState(currentUser?.station_id);
    const [autoUpdated, setAutoUpdated] = useState(false);
    const { data: usersData } = useQuery(GET_USERS);
    const [labelId, setLabelId] = useState();
    const [toId, setToId] = useState(truck.stationId);
    const [userId, setUserId] = useState();
    const [parkedLoad, setParkedLoad] = useState();
    const [remarks, setRemarks] = useState();
    const [price, setPrice] = useState();
    const [newCommentText, setNewCommentText] = useState('');
    
    const [commentForm] = Form.useForm();
    
    const [getLabelsData, { data: labelsData }] = useLazyQuery(GET_LABELS);
    const [getRoutesForecastData] = useLazyQuery(
        GET_ROUTES_FORECASTS, {
            onCompleted: (data) => {
                const vals = form.getFieldsValue(true)
                const route_val_json = JSON.stringify(data.routesForecasts.edges.find(edge => edge.node.vehicleId === paramsVehicleId)?.node)
                form.setFieldsValue({...vals, routeForecast: route_val_json})
            },
            onError: (error) => { console.log(error); }
        }
    );

    
    const [updateRequest] = useMutation(UPDATE_REQUEST, {
        onCompleted: (data) => {
            if (data.updateRequest.request?.overlaps?.length > 0) {
                message.error('選択したトラックは、リクエストされた時間帯にすでにスケジュールされています。リクエストを承認できません。');
            } else {
                message.success(autoUpdated ? 'リクエストを自動承認しました。' : 'リクエストが更新されました')
                refetchPostings()
            }
            setAutoUpdated(false)
        },
        onError: (error) => { console.log(error); }
    });

    const [createRequest] = useMutation(CREATE_REQUEST, {
        onCompleted: (data) => {
            message.success('リクエストを送信しました。');
            setVisible(false);
            if(parseInt(truck.stationId) === currentUser.station_id && parseInt(toId) === currentUser.station_id && parseInt(userId) === currentUser.id) {
                setAutoUpdated(true);
                updateRequest({ variables: { input: { id: data.createRequest.request.id, status: 'accepted' }}})
            }
        },
        onError: (error) => {
            message.error('リクエストを送信できませんでした。');
        }
    });

    const [createRoutesForecast] = useMutation(CREATE_ROUTES_FORECAST, {
        onCompleted: (data) => {
            createRequest({ variables: { input: {
                opsDate: data.createRoutesForecast.routesForecast.opsDate,
                fromId: stationId || currentUser?.station_id,
                forecastId: data.createRoutesForecast.routesForecast.id,
                userId,
                remarks,
                toId,
                status: 'sent',
                truckTableId: truck.id,
                postingId: posting?.id,
                price
            }}});
        },
        onError: (error) => {
            if (error?.networkError?.result?.errors[0]?.message?.includes('overlap')) {
                message.error('スケジュールが重複しています。')
            } else {
                message.error('リクエストを送信できませんでした。');
            }
        }

    });

    const [createTruckPostingComment] = useMutation(CREATE_TRUCK_POSTING_COMMENT, {
        onCompleted: (data) => {
            commentForm.resetFields();
            setNewCommentText('')
            refetchPostings();
        },
        onError: (error) => {
            console.log(error);
        }
    });
    
    const [updateTruckPostingComment] = useMutation(UPDATE_TRUCK_POSTING_COMMENT, {
        onCompleted: (data) => {
            refetchPostings();
        },
        onError: (error) => {
            console.log(error);
        }
    });
    
    const [deleteTruckPostingComment] = useMutation(DELETE_TRUCK_POSTING_COMMENT, {
        onCompleted: (data) => {
            refetchPostings();
        },
        onError: (error) => {
            console.log(error);
        }
    });

    const onNewFinish = ({ remarks, userId, toId, fromId, departureTime, arrivalTime, price, employeeId, employeeName }) => {
        setToId(toId);
        setUserId(userId);
        setRemarks(remarks);
        setPrice(price)
        createRoutesForecast({ variables: { input: {
            opsDate: moment(departureTime).format('YYYY-MM-DD'),
            stationId: fromId,
            vehicleStationId: truck.stationId,
            vehicleId: truck.vehicleId,
            maxTruckCapacity: truck.maxTruckCapacity,
            driverLicenseClass: truck.driverLicenseClass,
            vehicleType: truck.vehicleType,
            remarks,
            labelId,
            parkedLoad,
            departureTime,
            arrivalTime,
            pending: true,
            postingId: posting?.id,
            employeeId,
            employeeName
        }}});
    };

    const [form] = Form.useForm();
    const [newForm] = Form.useForm();
    const [, forceUpdate] = useState(); // To disable submit button at the beginning.

    useEffect(() => {
        forceUpdate({});
    }, []);

    useEffect(() => {
        if (visible && stationId) {
            const searchString =
                paramsVehicleId === "NoTruck"
                    ? `most_recent_forecast = true AND
                             vehicle_id = NoTruck AND
                             departure_time = "${searchedStartTime}" AND
                             arrival_time = "${searchedEndTime}" AND
                             station_id = ${currentUser?.station_id || stationId}`
                    : `most_recent_forecast = true AND
                             departure_time >= "${searchedStartTime}" AND
                             arrival_time < "${moment(searchedEndTime).add( 1, "minute")}" AND
                             station_id = ${currentUser?.station_id || stationId}`;
            getRoutesForecastData({
                variables: {
                    search: searchString
                }
            });
        }
    }, [getRoutesForecastData, truck, visible, stationId, searchedStartTime, searchedEndTime, paramsVehicleId, currentUser]);

    useEffect(() => {
        getLabelsData({ variables: { stationId: parseInt(stationId) } })
    }, [getLabelsData, visible, stationId]);

    const formItemStyle = { margin: '0 1rem', padding: 0 }
    const CustomLabel = ({ label, required }) => (
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <span>{label}</span>
            {required && <span style={{ color: '#ff4d4f', marginLeft: '4px' }}>*</span>}
        </div>
    );
    const labelInForm = (label, required) => {
        return (
            <Form.Item
                label={CustomLabel({ label, required })}
                colon={false}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={formItemStyle}
            />
        )
    }
    const comments = posting.comments
    const columns = [
        {
            dataIndex: 'key',
            rowScope: 'row',
            render: (text, record, index) => (
                <div style={{ backgroundColor: '#FAFAFA', height: '100%', padding: '1px' }}>
                    {text}
                </div>
            )
        },
        {
            dataIndex: 'data',
        }
    ]

    const data = [
        {
            key: labelInForm(isMobile() ? '依頼元' : '依頼元営業所', true),
            data: 
            <Form.Item
                name='fromId'
                initialValue={String(currentUser?.station_id)}
                rules={[{ required: true, message: '営業所を入力してください。' }]}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={formItemStyle}
            >
                <Select
                    showSearch
                    placeholder="選択してください"
                    style={{ width: 180 }}
                    className="custom-select"
                    disabled={!currentUser?.admin}
                    onChange={(fromId) => setStationId(fromId)}
                >
                    {
                        stationsData.stations.edges.map(
                            station => (
                                <Select.Option key={station.node.id} value={station.node.id}>
                                    {station.node.officialName}
                                </Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
        },
        {
            key: labelInForm(isMobile() ? '依頼先' : '依頼先営業所', true),
            data: 
            <Form.Item
                name='toId'
                initialValue={truck.stationId}
                rules={[{ required: true, message: '営業所を入力してください。' }]}
                style={formItemStyle}
            >
                <Select
                    disabled={disableToId}
                    showSearch
                    placeholder="選択してください"
                    style={{ width: 180 }}
                    className="custom-select"
                    onChange={
                        selectedId => {
                            setToId(selectedId)
                        }
                    }
                >
                    {
                        stationsData.stations.edges.map(
                            station => (
                                <Select.Option key={station.node.id} value={station.node.id}>
                                    {station.node.officialName}
                                </Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
        },
        {
            key: labelInForm('承認者', true),
            data: 
            <Form.Item
                name='userId'
                style={formItemStyle}
                rules={[{ required: true, message: '送り先を入力してください。' }]}
            >
                { usersData && <Select
                    showSearch
                    placeholder="選択してください"
                    style={{ width: 180 }}
                    className="custom-select"
                    filterOption={(input,option)=> option.children.join('').includes(input)}
                >
                    {
                        usersData.users.edges.filter(user => user.node.station?.id === toId).map(
                            user => (
                                <Select.Option key={user.node.id} value={user.node.id}>{user.node.lastName} {user.node.firstName}</Select.Option>
                            )
                        )
                    }
                </Select> }
            </Form.Item>
        },
        {
            key: labelInForm('ルート', true),
            data:
            <Form.Item
                name='label'
                style={formItemStyle}
                rules={[{ required: true, message: 'ルートを入力してください。' }]}
            >
                <Select
                    placeholder="選択してください"
                    showSearch
                    onChange={setLabelId}
                    optionFilterProp="children"
                    style={{ width: 180 }}
                    className="custom-select"
                    filterOption={(input, option) =>
                        option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            || option.props.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                >
                    {
                        labelsData && labelsData.mostFrequentlyUsedLabels.map(
                            label => (
                                label.enabled &&
                                    <Select.Option key={label.id} value={label.id}>{label.label.join('|')}</Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
        },
        {
            key: labelInForm('配送者ID', false),
            data:
            <Form.Item
                name="employeeId"
                style={formItemStyle}
                rules={[{ required: false, message: '正しい配送者IDを入力してください。' }]}
            >
                <Input className="custom-input" placeholder='配送者IDを入力してください。'/>
            </Form.Item>
        },
        {
            key: labelInForm('配送者名', false),
            data:
            <Form.Item
                name="employeeName"
                style={formItemStyle}
                
                rules={[{ required: false, message: '配送者名を入力してください。' }]}
            >
                <Input className="custom-input" placeholder='配送者名を入力してください。'/>
            </Form.Item>
        },
        {
            key: labelInForm('荷有り', true),
            data:
            <Form.Item
                name='parkedLoad'
                style={formItemStyle}
                className="custom-select"
                rules={[{ required: true, message: '荷物の有無を入力してください。' }]}
            >
                <Select
                    placeholder="選択してください" onChange={setParkedLoad}>
                    {
                        ["空", "荷あり"].map(parkedLoadType => (
                                <Select.Option key={parkedLoadType} value={parkedLoadType}>
                                    {parkedLoadType}
                                </Select.Option>
                            )
                        )
                    }
                </Select>
            </Form.Item>
        },
        {
            key: labelInForm('運行日', false),
            data: 
            <Form.Item
                name='opsDate'
                style={formItemStyle}
            >
                <Typography style={formItemStyle} className="custom-select">{truck.date}</Typography>
            </Form.Item>
        },
        {
            key: labelInForm('出発時刻', true),
            data: <DatePickerWithTimeInForm style={formItemStyle} border={{border: 'none'}} name='departureTime' initialTime={dateRange[0]} disableDate={(current) => { return current?.isBefore(searchedStartTime) || current?.isAfter(searchedEndTime) }}/>
        },
        {
            key: labelInForm('到着時刻', true),
            data: <DatePickerWithTimeInForm style={formItemStyle} border={{border: 'none'}} name='arrivalTime' initialTime={dateRange[1]} disableDate={(current) => { return current?.isBefore(searchedStartTime) || current?.isAfter(searchedEndTime) }}/>
        },
        {
            key: labelInForm('車種', false),
            data: 
            <Form.Item
                name='vehicleType'
                style={formItemStyle}
            >
                <Typography style={formItemStyle}>{truck.vehicleType}</Typography>
            </Form.Item>
        },
        {
            key: labelInForm('車両番号', false),
            data:
            <Form.Item
                name='vehicleId'
                style={formItemStyle}
            >
                <Typography style={formItemStyle}>{truck.vehicleId}</Typography>
            </Form.Item>
        },
        {
            key: labelInForm('運賃', false),
            data:
            <Form.Item
                name='price'
                style={formItemStyle}
                
                rules={[{ required: false, message: '運賃を入力してください。' }]}
            >
                <InputNumber min={0} style={{ border: 'none', width: '100%' }} placeholder='運賃を入力してください'/>
            </Form.Item>
        },
        {
            key: labelInForm('積載重量', false),
            data: 
            <Form.Item
                name='maxTruckCapacity'
                style={formItemStyle}
            >
                <Typography style={formItemStyle}>{truck.maxTruckCapacity}</Typography>
            </Form.Item>
        },
        {
            key: labelInForm('免許タイプ', false),
            data: 
            <Form.Item
                name='driverLicenseClass'
                style={formItemStyle}
            >
                <Typography style={formItemStyle}>{truck.driverLicenseClass}</Typography>
            </Form.Item>
        },
        {
            key: labelInForm('備考欄', false),
            data:
            <Form.Item
                name='remarks'
                style={formItemStyle}
                rules={[{ required: false }]}
            >
                <Input.TextArea style={{ border: 'none' }} placeholder='備考を入力してください' />
            </Form.Item>
        }

    ]
    const components = {
        body: {
            row: ({ children, ...restProps }) => (
                <tr {...restProps} style={{ height: '10px', padding: '1px' }}>
                    {children}
                </tr>
            ),
            cell: ({ children, ...restProps }) => {
                const style = {
                    height: '10px',
                    padding: '1px',
                    backgroundColor: typeof children[1] === 'string' ? '#FAFAFA' : undefined,
                };
                return (
                    <td {...restProps} style={style}>
                        {children}
                    </td>
                );
            },
        },
    };
    
    const NewRequestSubmitButton = () => {
        return (
        <Form.Item shouldUpdate>
                { () => (
                    <Button
                        disabled={
                            stationId === undefined ||
                            labelId === undefined ||
                            parkedLoad === undefined
                        }
                        type="primary"
                        htmlType='submit'
                    >
                        リクエストを送信する
                    </Button>
                )}
            </Form.Item>
        )
    }
    
    return <>
        {buttonMode ? <Button icon={<MessageOutlined/>} onClick={() => setVisible(true)}/> : <Button type='link' onClick={() => setVisible(true)}>リクエスト</Button>}
        <Drawer
            width={isMobile() ? '100vw' : 450}
            open={visible}
            footer={null}
            closable={false}
        >
            <Form
                name='request'
                form={newForm}
                onFinish={onNewFinish}
                colon={false}
            >
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        width: '100%',
                        marginBottom: '-30px'
                    }}
                >
                    <NewRequestSubmitButton />
                    <div>
                        <Popconfirm
                            title="投稿を削除しますか？"
                            okText="削除"
                            cancelText="キャンセル"
                            placement='bottomLeft'
                            onConfirm={() => handleDeletePosting(posting.id)}
                        >
                            <DeleteOutlined
                                style={{
                                    marginRight: 5,
                                    fontSize: 16,
                                    zIndex: 1,
                                }}
                            />
                        </Popconfirm>
                        <Button
                            type='text'
                            onClick={() => setVisible(false)}
                            style={{ zIndex: 1 }}
                        >
                            <CloseOutlined />
                        </Button>
                    </div>
                </div>
                <Divider style={{width: '112%', marginLeft: '-1.5rem'}} />
                <Table
                    columns={columns}
                    dataSource={data}
                    showHeader={false}
                    pagination={false}
                    components={components}
                    style={{ border: '1px solid #F0F0F0' }}
                />
            </Form>
            <div style={{ width: "100%", marginTop: '1rem'}}>
                    {comments?.length ? (
                        <CommentList comments={comments} posting={posting} currentUserId={currentUser.id} deleteComment={deleteTruckPostingComment} updateComment={updateTruckPostingComment}/>
                    ) : (
                        <Typography>コメントがありません。</Typography>
                    )}

                    <Form form={commentForm} onFinish={() => {
                        createTruckPostingComment({
                            variables: {
                                input: {
                                    postingId: posting.id,
                                    commentText: newCommentText,
                                }
                            }
                        
                        })
                    }}>
                        <Comment
                            avatar={<Avatar>{currentUser.last_name[0]}</Avatar>}
                            content={
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}
                                >
                                    <Input.TextArea
                                        value={newCommentText}
                                        placeholder="コメントを入力してください。"
                                        autoSize={{ minRows: 5, maxRows: 20 }}
                                        disabled={posting.status === "archived"}
                                        onChange={(e) => setNewCommentText(e.target.value)}
                                    />
                                    
                                    <Button
                                        style={{ height: "auto" }}
                                        disabled={posting.status === "archived" || newCommentText.length < 1}
                                        type="primary"
                                        htmlType="submit"
                                    >
                                        送信
                                    </Button>
                                </div>
                            }
                        />
                    </Form>
                </div>
                
        </Drawer>
    </>
};

export default TruckSearchRequestDrawer;
