import React from 'react';
import { PATHS } from '../../constants';
import { NavLink } from 'react-router-dom';
import {Typography,
        Row,
        Col,
        Button
 } from 'antd';
import {
    CarOutlined,
    ShoppingOutlined,
    NotificationOutlined
} from '@ant-design/icons';
import { isMobile } from '../../Utilities/isMobile';


const Home = () => {
    const MobileTopMenu = () => {
        return (<><Typography.Title level={5} style={{ margin: '1rem'}}>メニュー</Typography.Title>
        <Row gutter={[16, 16]} justify="center" style={{ padding: '10px' }}>
            <Col span={24}>
                <NavLink to={PATHS.truckPosting.split('/')[1]}><Button size='large' className="mobile-menu-list" icon={<CarOutlined />}>空車掲示板</Button></NavLink>
            </Col>
            <Col span={24}>
                <NavLink to={PATHS.freightPosting.split('/')[1]}><Button size='large' className="mobile-menu-list" icon={<ShoppingOutlined />}>荷物掲示板</Button></NavLink>
            </Col>
            <Col span={24}>
                <NavLink to={`/${PATHS.requestsList.split('/')[1]}`}><Button size='large' className="mobile-menu-list" icon={<NotificationOutlined />}>リクエスト一覧</Button></NavLink>
            </Col>
        </Row>
        </>);
    }

    return (
        <>
            {isMobile() ? <MobileTopMenu/> : null}
        </>
    );
}

export default Home;